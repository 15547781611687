import { Link } from 'react-router-dom';

//Stylesheets
import '../stylesheets/menu.css';

const Menu = () => {
  return (
    <header>
      <div className="menu-banner">
        <Link to="/">
          <img src="./banner_light.webp" alt="otofoto.org" />
        </Link>
      </div>
      <div className="menu-options">
        <Link to="/fotobudka">
          <div className="menu-item">Fotobudka</div>
        </Link>
        <Link to="/dj">
          <div className="menu-item">DJ</div>
        </Link>
        {/* <Link to="/fotograf">
          <div className="menu-item">Fotograf</div>
        </Link> */}
        <Link to="/dekoracje">
          <div className="menu-item">Dekoracje</div>
        </Link>
        <Link to="/samochody">
          <div className="menu-item">Samochody</div>
        </Link>
      </div>
    </header>
  );
}

export default Menu;
