import React from "react";
import { useState, useContext } from "react";

const visibilityContext = React.createContext("none");

// Custom context provider
export const OrderProvider  = ({children}) => {
    const order = useOrderProvider();
    return (
        <visibilityContext.Provider value={order}>{children}</visibilityContext.Provider>
    );
}

// orderHook
export const useOrder = () => {
    return useContext(visibilityContext);
}

export const useOrderProvider = () => {
    const [visibility, setVisibility] = useState("none");

    const show = () => {
        setVisibility("block");
    }

    const hide = () => {
        setVisibility("none");
    }

    return {show, hide, visibility};
}