import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { /* Landing, */ Dj, /* Fotograf, */ Aranzacje, Fotobudka, Samochody } from './components/Content';
import App from './components/App';

function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />}>
          <Route index element={<></>} /> {/* <Landing /> */}
          <Route path="fotobudka" element={<Fotobudka />} />
          <Route path="dj" element={<Dj />} />
          {/* <Route path="fotograf" element={<Fotograf />} /> */}
          <Route path="dekoracje" element={<Aranzacje />} />
          <Route path="samochody" element={<Samochody />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default Router;
