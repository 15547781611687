// ibraries
import { Outlet, useLocation } from 'react-router-dom';
import { Order } from './Content';

// Components
import Menu from './Menu';

// Hooks
import { OrderProvider } from '../hooks/orderHook';

const Footer = () => {
    return (
        <footer>
            <h1>Kontakt</h1>
            <p>Email: kontakt@otofoto.org</p>
            <p>Dominik (fotobudka, wynajem samochodów): 798 228 227</p>
            <p>Franciszek (DJ): 572 670 348</p>
            <p>Nadia (dekoracje): 538 121 038</p>
        </footer>
    )
}

const App = () => {
    const location = useLocation();
    const path = location.pathname.replace("/", "");
    const text = {
        "" : "Profesjonalna obsługa Twojej imprezy!",
        "dekoracje" : "Dekoracje na Twoją imprezę!",
        "fotobudka" : "Fotobudka na Twoją imprezę!",
        "fotograf" : "Fotograf  na Twoją imprezę!",    
        "dj" : "DJ na Twoją imprezę!",
        "samochody": "Samochód na Twoją imprezę!"
    };

    return (
        <>
            <Menu />
            <div className="outlet">
                <OrderProvider>
                    <Order />
                    <div className={`first-tile main-background ${ path } `}>
                        <div className="title">
                            <p>
                                <img src="./banner_light.webp" alt="OtoFoto" />
                            </p>
                            <p className="subtitle">{ (text[path]) ? text[path] : "Fotobudka na Twoją imprezę!" }</p>
                        </div>
                    </div>
                    <Outlet />
                </OrderProvider>
            </div>
            { (path !== "") && <div className="triangle-bottomright bottomdark"></div> }
            <Footer />
        </>
    )
}

export default App;